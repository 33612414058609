import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import figureFormatter from "@/services/utils/figureFormatter";
import { useStore } from "vuex";
import useDate from '@/services/utils/day';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const pdfPrinter = () => {
    const { formatDate } = useDate();
    const store = useStore();
    const { commaFormat } = figureFormatter();
    const userName = store.state.user.user.name;

    const exportToPDF = (company, data) => {
        var doc = {
            pageSize: 'A4',
            pageMargins: [ 25, 85, 25, 75 ],
            header: getHeader(company),
            footer: getFooter(userName),
            content: getContent(data),
            styles : {
                header: {
                    fontSize: 24
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 8,
                font: 'SulaimanLipi'
            },
            info: {
                title: 'Daily Cash Summary'
            }
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }

    const getContent = ({ general, receiptReport, cashBank}) => {
        return [
            {
                text: 'Daily Cash Summary',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 0, 0],
                color: 'black',
                bold: true,
                fontSize: 14,
                decoration: 'underline'
            },
            {
                stack: [
                    {
                        text: general.payload ? (`Date: ${general.payload.startDate} - ${general.payload.endDate}`) : ''
                    },
                    {
                        text: general.payload ? (`Account Head: ${general.payload.accountHead}`) : ''
                    },
                ]
            },
            {
                style: 'tableExample',
                margin: [0, 10, 0, 10],
                table: {
                    widths: [60, '*', 60, 60, 60],
                    body: getCashBankBody(general),
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            },
            {
                text: 'Daily Receipt & Payment',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 0, 0],
                color: 'black',
                bold: true,
                fontSize: 14,
                decoration: 'underline'
            },
            {
                margin: [0, 10, 0, 10],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        style: 'tableExample',
                        margin: [0, 0, 8, 0],
                        table: {
                            widths: ['*', 60],
                            body: getReceiptBody(receiptReport)
                        },
                        layout: {
                            fillColor: function (rowIndex, node, columnIndex) {
                                return (rowIndex === 0) ? '#f3f2f7' : null;
                            },
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        }
                    },
                    {
                        width: '50%',
                        style: 'tableExample',
                        margin: [8, 0, 0, 0],
                        table: {
                            widths: ['*', 60],
                            body: getPaymentBody(receiptReport)
                        },
                        layout: {
                            fillColor: function (rowIndex, node, columnIndex) {
                                return (rowIndex === 0) ? '#f3f2f7' : null;
                            },
                            hLineWidth: function (i, node) {
                                return 0.5;
                            },
                            vLineWidth: function (i, node) {
                                return 0.5;
                            }
                        }
                    }
                ]
            },
            {
                text: 'Today\'s Cash & Bank Position',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 0, 0],
                color: 'black',
                bold: true,
                fontSize: 14,
                decoration: 'underline'
            },
            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: ['*', 55],
                    body: getBankPositionBody(cashBank)
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex === 0) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function (i, node) {
                        return 0.5;
                    },
                    vLineWidth: function (i, node) {
                        return 0.5;
                    }
                }
            }
        ]
    }

    const getPaymentBody = ({ balances }) => {
        const paymentBalance = balances && balances.payment ? balances.payment : [];

        const rows = paymentBalance.map((item) => {
            return [
                { text: item.account_head_name },
                { text: commaFormat(item.total), alignment: 'right' }
            ];
        })

        rows.push([
            { text: 'Total Payment', bold: true },
            { text: balances && balances.payment_total ? commaFormat(balances.payment_total.total) : 0, alignment: 'right', bold: true }
        ])

        rows.unshift([
            { text: 'Payment', bold: true, alignment: 'left', colSpan: 2 },
            { text: '' }
        ]);

        rows.unshift([
            { text: 'Account Head', bold: true },
            { text: 'Payment', bold: true, alignment: 'right' }
        ]);

        return rows;
    }

    const getReceiptBody = ({ balances }) => {
        const receiptBalance = balances && balances.receipt ? balances.receipt : [];

        const rows = receiptBalance.map((item) => {
            return [
                { text: item.account_head_name },
                { text: commaFormat(item.total), alignment: 'right' }
            ];
        })

        rows.push([
            { text: 'Total Receipt', bold: true },
            { text: balances && balances.receipt_total ? commaFormat(balances.receipt_total.total) : 0, alignment: 'right', bold: true }
        ])

        rows.unshift([
            { text: 'Receipt', bold: true, alignment: 'left', colSpan: 2 },
            { text: '' }
        ]);

        rows.unshift([
            { text: 'Account Head', bold: true },
            { text: 'Receipt', bold: true, alignment: 'right' }
        ]);

        return rows;
    }

    const getCashBankBody = (data) => {
        const header = getCashBankHead();
        const row = getCashStatement(data);
        return [ header, ...row ];
    }

    const getCashStatement = ({ledgerData, opening_balance, closing_balance, total_debit, total_credit}) => {
        const tableRow = ledgerData.map((item) => {
            return [
                { text: item.date },
                { text: item.line_description },
                { text: commaFormat(item.debit), alignment: 'right' },
                { text: commaFormat(item.credit), alignment: 'right' },
                { text: commaFormat(item.balance), alignment: 'right' }
            ]
        });
        tableRow.unshift(getOpeningBalance(opening_balance));
        tableRow.push(getClosingBalance(closing_balance, total_debit, total_credit));
        return tableRow;
    }

    const getOpeningBalance = (openingBalance) => {
        return [
            { text: 'Opening Balance', colSpan: 4, alignment: 'left', bold: true },
            { text: '',},
            { text: '' },
            { text: '' },
            { text: openingBalance ? commaFormat(openingBalance) : 0, alignment: 'right' }
        ]
    }

    const getClosingBalance = (closingBalance, totalDebitBalance, totalCreditBalance) => {
        return [
            { text: 'Closing Balance', alignment: 'left', colSpan: 2, bold: true },
            { text: ''},
            { text: totalDebitBalance ? commaFormat(totalDebitBalance) : 0 },
            { text: totalCreditBalance ? commaFormat(totalCreditBalance) : 0, alignment: 'right' },
            { text: closingBalance ? commaFormat(closingBalance) : 0, alignment: 'right' }
        ]
    }

    const getBankPositionBody = (cashData) => {

        const rows = cashData.map((item) => {
            return [
                { text: item.name },
                { text: item.closing_balance ? commaFormat(item.closing_balance) : 0, alignment: 'right' }
            ]
        });

        rows.unshift(getBankPositionHead());

        rows.push([
            { text: 'Total', bold: true, alignment: 'left' },
            { text: commaFormat(getTotalCashBalance(cashData)), alignment: 'right' }
        ])

        return rows;
    }

    const getTotalCashBalance = (data) => {
        return data.reduce((sum, item) => sum + Number(item['closing_balance']), 0)
    }

    const getCashBankHead = () => {
        return [
            { text: 'DATE', bold: true },
            { text: 'LINE DESCRIPTION', bold: true },
            { text: 'RECEIPT ', alignment: 'right', bold: true },
            { text: 'PAYMENT', alignment: 'right', bold: true },
            { text: 'BALANCE', alignment: 'right', bold: true }
        ]
    }

    const getBankPositionHead = () => {
        return [
            { text: 'Account Name', bold: true },
            { text: 'BALANCE', alignment: 'right', bold: true }
        ]
    }

    const getHeader = (company) => {
        return {
            margin: [ 30, 25, 10, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60
                },
                {
                    alignment: 'right',
                    stack: [
                        company.name,
                        'Address: ' + company.address,
                        'Phone: ' + company.phone,
                        'E-mail: ' + company.email
                    ]
                }
            ]
        }
    }

    const getFooter = (userName) =>{
        return {
            margin: [ 25, 0 ],
            columns: [
                {
                    columns: [
                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]
                },
                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    return {
        exportToPDF
    }
}

export default pdfPrinter;